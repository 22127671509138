import * as React from 'react';

import Contact from '../components/Contact';
import Navbar from '../components/Navbar';

import { UserContextProvider } from '../context/UserContext';
import { Helmet } from 'react-helmet';

import './index.module.scss';

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Tracy - Your Ads Revenue Booster</title>
        <link rel='canonical' href='https://tracy.pages.dev/contact' />
      </Helmet>

      <UserContextProvider>
        <Navbar />
        <Contact />
      </UserContextProvider>
    </>
  );
};

export default IndexPage;

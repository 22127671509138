import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import Discord from '../../assets/discord.png';

import * as styles from './Contact.module.scss';

function Contact() {
  return (
    <div className={styles.container}>
      <h3 className={styles.subheading}>
        We made a <span className={styles.red}>Discord</span> channel to answer
        all your quesions
      </h3>

      <h1 className={styles.heading}>
        Join our discord channel to{' '}
        <span className={styles.red}>stay in touch</span>
      </h1>

      <OutboundLink
        href='https://discord.gg/XS6PjSS7Xz'
        rel='noreferrer noopener'
        target='_blank'
      >
        <img src={Discord} alt='Discord' height='54' />
      </OutboundLink>
    </div>
  );
}

export default Contact;
